<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import type { Brand, ProductCategory } from '$types';

  const variant = defineModel('variant', { type: Object, required: true });

  const props = defineProps({
    product: { type: Object, required: true },
    brand: { type: Object as PropType<Brand>, required: true },
    page: Object,
    category: { type: Object as PropType<ProductCategory>, required: false },
  });

  const photos = computed(() => {
    return [
      ...variant.value.photos,
      ...props.product.photos,
      ...(props.product.gallery?.photos || []),
    ];
  });
</script>
<template>
  <div class="details container">
    <div class="gallery">
      <SfProductGallery v-bind="{ photos }" />
    </div>
    <div class="content">
      <header class="header">
        <ShopProductViewTags :product :variant icon />
        <h1 v-html="variant.title" />
        <SfStars v-if="product.reviewsCount > 0" :count="product.reviewsCount" :rating="product.reviewsAverage" />
      </header>

      <div class="wrapper">
        <div class="left">
          <template v-if="product.description_short">
            <div class="description" v-html="product.description_short" />
          </template>
          <ShopProductViewMeta :brand :category :product :variant />
          <ShopProductViewActions :variant />
        </div>
        <div class="right">
          <ShopProductViewPicker :product v-model:variant="variant" />
        </div>
      </div>

      <ShopProductViewFiles :product />
    </div>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-product-gallery-view-details-button-width: 3rem;
    --sf-product-gallery-view-details-button-height: 3rem;
    --sf-product-gallery-view-details-button-space: 0;
    --sf-product-gallery-view-details-button-icon-size: 1.5rem;
  }

  .product-gallery .product-gallery__images {
    --sf-carousel-button-width: var(--sf-product-gallery-view-details-button-width);
    --sf-carousel-button-height: var(--sf-product-gallery-view-details-button-height);
    --sf-carousel-button-space: var(--sf-product-gallery-view-details-button-space);
    --sf-carousel-button-icon-size: var(--sf-product-gallery-view-details-button-icon-size);
  }
</style>
<style scoped lang="scss">
  .details {
    display: flex;
    gap: 2rem;
    margin-bottom: var(--margin-default);
    padding: var(--padding-card);
    background-color: var(--background-card);
    border-radius: var(--border-radius);

    .gallery {
      height: fit-content;
      width: 40%;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 60%;
      gap: 2rem;

      .header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h1 {
          font-weight: var(--font-weight-bold);
          font-size: 1.5rem;
        }
      }

      .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        .left {
          display: flex;
          flex-direction: column;
          flex: 1 1 50%;
          gap: 1rem;

          .description {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .details {
      .gallery {
        width: 45%;
      }

      .content {
        width: 55%;

        .wrapper {
          grid-template-columns: 1fr
        }
      }
    }
  }

  @media (max-width: 992px) {
    .details {
      flex-direction: column;
      padding-bottom: 1rem;

      .gallery {
        width: 100%;
      }

      .content {
        width: 100%;
        gap: 1rem;

        .header {
          h1 {
            font-size: 1.25rem;
          }
        }

        .wrapper {
          gap: 1rem;

          .left {
            .description {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
</style>
