<script setup lang="ts">
  import { useBreakpoints } from '$storefront';
  import type { PropType } from 'vue';
  import { DocumentType } from '$types';

  type OrderDocument = {
    id: number;
    type: DocumentType;
    reference_number: string;
    download: string;
  }

  const breakpoints = useBreakpoints();

  defineProps({
    order: { type: Object, required: true },
    repayment: { type: String, required: false },
    documents: { type: Array as PropType<OrderDocument[]>, required: false, default: [] },
  });
</script>
<template>
  <div class="order-completion">
    <SfTile large>
      <template #title>
        <div class="order-completion__header">
          <h1 class="account__heading" v-if="breakpoints.lg">
            {{ $t('Zamówienie Nr') }} {{ order.reference_number }}
          </h1>
          <span class="order-completion__date">
            {{ $t('Data zamówienia:') }}
            <SfDateTime :value="order.ordered_at" />
          </span>
        </div>
      </template>
      <template #headerAction>
        <div class="order-completion__status">
          <SfOrderStatus :status="order.status" />
        </div>
      </template>
      <template #default>
        <div class="order-completion__products">
          <OrderDetailsLine v-for="item in order.items" :key="item.id" :item="item" />
        </div>
        <OrderDetailsSummary :order="order" />
      </template>
    </SfTile>
    <SfTile>
      <div class="order-completion__data">
        <SfTile small>
          <template #title>
            <h4>{{ $t('Adres dostawy') }}</h4>
          </template>
          <div class="order-completion__data-info">
            <SfAddressFormatted :model="order" prefix="shipping" />
          </div>
        </SfTile>
        <SfTile small>
          <template #title>
            <h4 class="order-completion__data-heading">{{ $t('Dane kupującego') }}</h4>
          </template>
          <div class="order-completion__data-info">
            <SfAddressFormatted :model="order" prefix="billing" />
          </div>
        </SfTile>
        <SfTile small v-if="order.shipping_option_name">
          <template #title>
            <h4 class="order-completion__data-heading">{{ $t('Sposób dostawy') }}</h4>
          </template>
          <div class="order-completion__data-info">
            <p class="order-completion__shipping-name">{{ order.shipping_option_name }}</p>
          </div>
        </SfTile>
        <SfTile small v-if="order.billing_method_option_name">
          <template #title>
            <h4 class="order-completion__data-heading">{{ $t('Płatność') }}</h4>
          </template>
          <div class="order-completion__data-info">
            <p class="order-completion__billing-name">{{ order.billing_method_option_name }}</p>
          </div>
        </SfTile>
      </div>
    </SfTile>
    <SfTile v-if="documents.length > 0">
      <template #title>
        <h4>{{ $t('Dokumenty do zamówienia') }}</h4>
      </template>
      <template #default>
        <div class="order-documents">
          <ul>
            <li v-for="doc in documents">
              <a :href="doc.download" target="_blank">
                <SfIconDocument />
                <span>{{ doc.type }}</span>
                <span>{{ doc.number }}</span>
              </a>
            </li>
          </ul>
        </div>
      </template>
    </SfTile>
    <div class="order-completion__button-wrapper">
      <SfButton v-if="repayment" primary :href="repayment">
        {{ $t('Opłać ponownie') }}
      </SfButton>
      <slot name="actions" />
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-order-details-old-price: var(--sf-product-price-catalog-color, rgb(200, 0, 0));
  }

  .order-documents {
    ul {
      display: grid;
      grid-template-columns: 1rem auto 1fr;
      column-gap: 1rem;
      row-gap: 0.5rem;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: contents;

        a {
          display: contents;

          span {
            font-size: 0.875rem;
            line-height: 1rem;
          }
        }
      }
    }
  }

  .order-completion {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    &__products,
    &__product-content,
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__date {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
      font-weight: var(--font-weight-semi);
    }

    &__status {
      font-weight: var(--font-weight-semi);
      color: var(--sf-status-color-success);
      margin-left: 0.375rem;
    }

    &__data {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: flex-start;
      gap: 2rem;

      .placeholder {
        --sf-data-placeholder-padding: 0.375rem
      }
    }

    &__data-info {
      font-size: 0.875rem;
    }

    &__button-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 0.75rem;
    }

    &__product-price {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-end;
    }
  }

  @include media.query(xxl) {
    .order-completion__data {
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }

  @include media.query(xl) {
    .order-completion__data {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media.query(lg) {
    .order-completion {
      row-gap: 1rem;

      &__heading {
        font-size: 1.375rem;
      }

      &__status {
        margin-top: 0.375rem;
      }

      &__product {
        padding-bottom: 1rem;
      }

      &__data-heading {
        font-size: 1rem;
      }
    }
  }

  @include media.query(sm) {
    .order-completion {
      &__data {
        grid-template-columns: 1fr;
      }

      &__product-heading {
        font-size: 1rem;
      }

      &__product-price {
        font-size: 0.875rem;
      }

      &__wrapper {
        gap: 0.625rem;
        padding: 0.625rem 0.125rem;
      }

      &__content {
        gap: 0.625rem;
      }

      &__button-wrapper .btn {
        flex-grow: 1;
      }
    }
  }
</style>
