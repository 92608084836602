<script setup lang="ts">
  import { $post, route } from '@plenny/visitor';
  import { trans } from '@plenny/translator';
  import { computed, type PropType } from 'vue';
  import type { Country } from '$types';

  defineProps({
    countries: { type: Array as PropType<Country[]>, required: true },
  });

  type SignUpData = {
    accept_conditions: boolean;
    accept_newsletter: boolean;
    accept_rodo: boolean;
    country_code: string;
    email: string | null;
    name: string | null;
    password: string | null;
    password_confirmation: string | null;
    phone: string | null;
    type: string;
  }

  const initial = {
    type: 'personal',
    country_code: __default_country_code,
  };

  const accountTypes = [
    { label: trans('Osobiste'), value: 'personal' },
    { label: trans('Firmowe'), value: 'company' },
  ];

  function actionSubmit(data: SignUpData) {
    return $post(route('web.account.register.store'), data);
  }

  const termsAndConditions = computed(() => {
    return trans('Akceptuję <a href=":url" class="label-link" target="_blank">regulamin</a> sklepu.', { url: route('web.terms_and_condition.index') });
  });

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" class="label-link" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });

  const boxChecked = computed(() => {
    return trans('Pola oznaczone <b>*</b> są wymagane.');
  });
</script>

<template>
  <VisitorForm :initial="initial" :submit="actionSubmit" v-slot="{ data, submitting }" class="sign-up-form">
    <div class="sign-up-form__info" v-if="$slots.info">
      <slot name="info"></slot>
    </div>
    <div class="sign-up-form__form">
      <SfFormRadio :label="$t('Chcę założyć konto')" name="type" :options="accountTypes" required />
      <SfFormSelect :label="$t('Kraj')" name="country_code" :options="countries" required />
      <SfFormInput v-if="data.type === 'company'" :label="$t('Nazwa firmy')" name="company" required />
      <SfFormInput v-if="data.type === 'personal'" :label="$t('Imię i nazwisko')" name="name" required />
      <SfFormInput :label="$t('E-mail')" name="email" required autocomplete="username" />
      <SfFormInput :label="$t('Telefon')" name="phone" />
      <SfFormInput :label="$t('Hasło')" type="password" name="password" required autocomplete="new-password" />
      <SfFormInput :label="$t('Potwierdź hasło')" type="password" name="password_confirmation" required autocomplete="new-password" />
      <SfAddressForm billable typed external-type="type" external-country="country_code" />
    </div>
    <div class="sign-up-form__conditions">
      <SfFormSwitch name="accept_conditions" required>
        <span v-html="termsAndConditions" />
      </SfFormSwitch>
      <SfFormSwitch name="accept_rodo" required>
        <span v-html="privacyPolicy" />
      </SfFormSwitch>
      <SfFormSwitch name="accept_newsletter">
        {{ $t('Chcę zapisać się do newslettera') }}
      </SfFormSwitch>
    </div>
    <p class="sign-up-form__required-fields" v-html="boxChecked" />
    <SfButton type="submit" primary :loading="submitting">
      {{ $t('Załóż konto') }}
    </SfButton>
  </VisitorForm>
</template>

<style lang="scss">
  :root {
    --sf-sign-up-form-required-color: var(--sf-status-color-danger);
    --sf-sign-up-form-required-font-size: 0.75rem;
    --sf-sign-up-form-conditions-link-color: var(--primary-color);
    --sf-sign-up-form-conditions-link-color-hover: var(--primary-color-hover);
    --sf-sign-up-form-conditions-trans-duration: 200ms;
  }

  .sign-up-form {
    &__form,
    &__conditions {
      display: flex;
      flex-direction: column;
    }

    &__form, &__conditions {
      gap: var(--form-gap-y) var(--form-gap-x);
    }

    &__conditions a {
      text-decoration: underline;
      color: var(--sf-sign-up-form-conditions-link-color);
      transition-property: color;
      transition-duration: var(--sf-sign-up-form-conditions-trans-duration);

      @media(hover: hover) {
        &:hover {
          color: var(--sf-sign-up-form-conditions-link-color-hover);
        }
      }
    }

    &__required-fields {
      font-size: var(--sf-sign-up-form-required-font-size);

      b {
        color: var(--sf-sign-up-form-required-color);
      }
    }
  }
</style>
