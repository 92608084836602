import { useShared, useSession } from '@plenny/visitor';
import { computed, toValue } from 'vue';
import type { MaybeRefOrGetter, ComputedRef } from '@vue/reactivity';
import { Features } from '$features';

interface StockMeta {
  total: number;
  current: number;
  levels: Record<number, string>;
}

type CartItem = {
  variant_id: number;
  amount: string;
}

type Cart = {
  items: CartItem[];
}

type Variant = {
  id: number;
  stockOnOrder: boolean;
  stockAvailable: string;
  stockMeta: StockMeta | undefined;
}

type Source = MaybeRefOrGetter<Variant | undefined> | ComputedRef<Variant | undefined> | undefined;

export function useVariantStock(source?: Source) {
  const shared = useShared();
  const session = useSession();

  const canOrderOverSupply = computed(() => {
    if (Features.ordersOverSupply.isEnabled()) {
      if (Features.ordersOverSupplyForBusinessOnly.isEnabled()) {
        if (session.value.user && session.value.user.b2b) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  });

  const stockOnOrder = computed(() => {
    return toValue(source)?.stockOnOrder || false;
  });

  const amountInStock = computed(() => {
    return toValue(source)?.stockAvailable || 0;
  });

  const amountInCart = computed(() => {
    const cart = shared.value.cart as Cart;
    const variant = toValue(source);

    if (!cart || !variant) {
      return 0;
    }

    const item = cart.items.find((item) => {
      return item.variant_id === variant!.id;
    });

    if (!item) {
      return 0;
    }

    return item.amount;
  });

  const amountAvailable = computed(() => {
    if (canOrderOverSupply.value) {
      return Number.POSITIVE_INFINITY;
    }

    return Number(amountInStock.value) - Number(amountInCart.value);
  });

  const inStock = computed(() => {
    if (stockOnOrder.value) {
      return true;
    }

    return Number(amountInStock.value) > 0;
  });

  const available = computed(() => {
    if (stockOnOrder.value) {
      return true;
    }

    if (canOrderOverSupply.value) {
      return true;
    }

    if (Number(amountInStock.value) > 0) {
      return Number(amountInCart.value) < Number(amountInStock.value);
    }

    return false;
  });

  return {
    stockOnOrder,
    amountInStock,
    amountInCart,
    amountAvailable,
    inStock,
    available,
  };
}
