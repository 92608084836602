<script setup lang="ts">
  import { type PropType } from 'vue';
  import { $delete, route } from '@plenny/visitor';
  import type { Breadcrumb } from '$types';
  import { useBreakpoints, useOffersPermissions, useSettlementsPermissions } from '$storefront';

  defineProps({
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
  });

  const breakpoints = useBreakpoints();

  const { canRequestOffers } = useOffersPermissions();
  const { canRequestSettlements } = useSettlementsPermissions();

  function actionLogout() {
    $delete(route('web.account.session.destroy'));
  }
</script>
<template>
  <div class="account">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div class="account__container">
      <div class="account__menu" v-if="breakpoints.lg">
        <SfButton :href="route('web.account.show')" explicit transparent>
          <template #before>
            <SfIconPanel aria-hidden="true" />
          </template>
          <template #default>
            {{ $t('Panel') }}
          </template>
        </SfButton>
        <SfButton :href="route('web.account.order.index')" transparent>
          <template #before>
            <SfIconBox />
          </template>
          <template #default>
            {{ $t('Moje zamówienia') }}
          </template>
        </SfButton>
        <SfButton v-if="canRequestOffers" :href="route('web.account.offers.index')" transparent>
          <template #before>
            <SfIconEnvelopePaper />
          </template>
          <template #default>
            {{ $t('Zapytania ofertowe') }}
          </template>
        </SfButton>
        <SfButton v-if="canRequestSettlements" :href="route('web.account.settlements.index')" transparent>
          <template #before>
            <SfIconCoin />
          </template>
          <template #default>
            {{ $t('Rozrachunki') }}
          </template>
        </SfButton>
        <SfButton v-if="canRequestSettlements" :href="route('web.account.warehouse.documents.index')" transparent>
          <template #before>
            <SfIconBox />
          </template>
          <template #default>
            {{ $t('Dok. magazynowe') }}
          </template>
        </SfButton>
        <SfButton :href="route('web.account.edit')" transparent>
          <template #before>
            <SfIconPersonGear />
          </template>
          <template #default>
            {{ $t('Dane konta') }}
          </template>
        </SfButton>
        <SfButton :href="route('web.account.notifications.edit')" transparent>
          <template #before>
            <SfIconBell />
          </template>
          <template #default>
            {{ $t('Powiadomienia') }}
          </template>
        </SfButton>
        <SfButton :href="route('web.account.review.index')" transparent>
          <template #before>
            <SfIconChat />
          </template>
          <template #default>
            {{ $t('Opinie') }}
          </template>
        </SfButton>
        <SfButton :href="route('web.account.wishlist.index')" transparent>
          <template #before>
            <SfIconHeart />
          </template>
          <template #default>
            {{ $t('Ulubione') }}
          </template>
        </SfButton>
        <SfButton :href="route('web.account.address.delivery.index')" transparent>
          <template #before>
            <SfIconGeo />
          </template>
          <template #default>
            {{ $t('Adresy dostawy') }}
          </template>
        </SfButton>
        <SfButton :href="route('web.account.address.billing.index')" transparent>
          <template #before>
            <SfIconDocument />
          </template>
          <template #default>
            {{ $t('Dane do faktury') }}
          </template>
        </SfButton>
        <SfButton v-if="$settings.FeatureLoyaltyEnabled" :href="route('web.account.loyalty.index')" transparent>
          <template #before>
            <SfIconCoin />
          </template>
          <template #default>
            {{ $t('Punkty lojalnościowe') }}
          </template>
        </SfButton>
        <SfButton v-if="$settings.FeatureAffiliateEnabled" :href="route('web.account.affiliate.index')" transparent>
          <template #before>
            <SfIconShare />
          </template>
          <template #default>
            {{ $t('Program partnerski') }}
          </template>
        </SfButton>
        <SfButton transparent @click="actionLogout">
          <template #before>
            <SfIconPower />
          </template>
          <template #default>
            {{ $t('Wyloguj się') }}
          </template>
        </SfButton>
      </div>
      <div class="account__content">
        <slot />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-account-menu-background: rgb(255, 255, 255);
    --sf-account-menu-padding-x: 1.5rem;
    --sf-account-menu-padding-y: 1.5rem;

    --sf-account-color-white: rgb(255, 255, 255);
    --sf-account-color-black: rgb(0, 0, 0);
    --sf-account-color-dark-grey: rgb(80, 80, 80);
    --sf-account-color-medium-grey: rgb(220, 220, 220);
    --sf-account-color-light-background: rgb(240, 240, 240);

    --sf-account-max-width: 1400px;
  }

  .account {
    margin: 0 auto 100px;
    width: 98%;
    max-width: var(--sf-account-max-width);

    &__heading {
      font-size: 28px;
      font-weight: var(--font-weight-bold);

      &.hide {
        display: none;
      }
    }

    &__container {
      margin-top: 1.875rem;
      display: grid;
      grid-template-columns: 1fr 4fr;
      align-items: flex-start;
      gap: 3rem;
    }

    &__menu {
      --sf-btn-justify-content: flex-start;

      background-color: var(--sf-account-menu-background);
      padding: var(--sf-account-menu-padding-x) var(--sf-account-menu-padding-y);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: var(--border-radius);

      svg {
        font-size: 1.25rem;
      }

      .btn {
        font-weight: var(--font-weight-semi);

        &.active {
          color: var(--sf-btn-transparent-foreground-active);
          background-color: var(--sf-btn-transparent-background-active);
        }
      }
    }
  }

  @include media.query(lg) {
    .account {
      margin-bottom: 50px;
      width: 100%;

      &__container {
        grid-template-columns: 1fr;
        margin: 0 auto 3.125rem;
      }
    }
  }
</style>
