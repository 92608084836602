<script setup lang="ts">
  import { $patch, $delete, route } from '@plenny/visitor';
  import { computed, ref, watch } from 'vue';
  import { useVariantStock } from '$storefront';
  import { closestMultipleOf } from '$support';

  const props = defineProps({
    item: { type: Object, required: true },
  });

  const variant = computed(() => ({
    id: props.item.variant_id,
    stock_available: props.item.stock_available,
    stock_on_order: props.item.stock_on_order,
  }));

  const stockUnitBase = computed(() => {
    if (props.item) {
      return props.item.stock_unit_base;
    } else {
      return '1.0000';
    }
  });

  const amount = ref(Number(props.item.amount));

  const { amountAvailable } = useVariantStock(variant);

  function onAmountChange(event, item) {
    amount.value = closestMultipleOf(Number(event.target.value), Number(item.stock_unit_base));

    $patch(route('api.v1.web.cart.item.update', { item }), {
      amount: amount.value,
    });
  }

  function onDestroy(item) {
    $delete(route('api.v1.web.cart.item.destroy', { item }));
  }

  const target = computed(() => {
    return route('web.product.show', { slug: props.item.slug });
  });

  watch(() => props.item, (item) => {
    amount.value = Number(item.amount);
  });
</script>
<template>
  <SfListCard class="cart-item">
    <template #image>
      <MediaPicture :photo="item.photo" sizes="240x240 2x, 120x120" class="cart-item__photo" />
    </template>
    <template #actions>
      <SfProductCatalogPrice :item="item" />
      <SfProductPrice :price="item" row class="cart-item__price" />
      <SfProductOmnibus :price="item" />
      <div class="cart-item__buttons">
        <SfWishlistButton :variant="item.variant_id" />
        <SfButton square transparent danger @click="onDestroy(item)" :aria-label="$t('Usuń produkt z koszyka')" :title="$t('Usuń produkt z koszyka')">
          <template #after>
            <SfIconTrash />
          </template>
        </SfButton>
      </div>
    </template>
    <template #default>
      <header class="cart-item__header">
        <h2 class="cart-item__title" @click="$redirect(target)" tabindex="0">
          {{ item.variant_title }}
        </h2>
        <p v-if="item.variant_sku" class="cart-item__sku">
          {{ $t('SKU') + ': ' + item.variant_sku }}
        </p>
      </header>
      <section class="cart-item__stock">
        <SfProductStock :variant="item" />
      </section>
      <section class="cart-item__details">
        <div class="cart-item__amount">
          <SfFormNumber
            :min="stockUnitBase"
            :step="stockUnitBase"
            :max="amountAvailable"
            required
            v-model="amount"
            @change="onAmountChange($event, item)"
            :label="$t('Ilość')"
            :id="'item-id-' + item.variant_id + '-amount'"
          />
        </div>
      </section>
      <section v-if="amount > item.stockMeta.current" class="cart-item__stock__warning">
        <p v-html="$t('Zamawiasz więcej niż dostępne w wybranym magazynie. Może to wydłużyć czas realizacji zamówienia.')" />
      </section>
    </template>
    <template #icons>
      <SfProductTag :visible="item.regular_gross != item.price_gross" tag-type="discount" icon>
        {{ $t('Promocja') }}
      </SfProductTag>
    </template>
  </SfListCard>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-cart-item-trnasition-duration: 200ms;
    --sf-cart-item-trnasition-timing-func: ease-out;
    --sf-cart-item-title-color: rgb(0, 0, 0,);
    --sf-cart-item-sku-color: rgb(87, 87, 87);
    --sf-cart-item-title-color-hover: rgb(70, 70, 70);

    --sf-cart-item-border-color: rgb(240, 240, 240);
    --sf-cart-item-border-width: 1px;
    --sf-cart-item-border-style: solid;

    --sf-cart-item-box-shadow: none;
  }

  .cart-item {
    --sf-card-border-color: var(--sf-cart-item-border-color);
    --sf-card-border-width: var(--sf-cart-item-border-width);
    --sf-card-border-style: var(--sf-cart-item-border-style);
    --sf-card-box-shadow: var(--sf-cart-item-box-shadow);
    --sf-product-price-current-font-size: 1.25rem;
    --sf-product-price-gap-row: 0.5rem;

    &__title {
      font-size: 1.25rem;
      color: var(--sf-cart-item-title-color);
      transition: var(--primary-transition);
      transition-property: color;
      transition-duration: var(--sf-cart-item-trnasition-duration);
      transition-timing-function: var(--sf-cart-item-trnasition-timing-func);
      cursor: pointer;

      @media(hover: hover) {
        &:hover {
          color: var(--sf-cart-item-title-color-hover);
          text-decoration: underline;
        }
      }
    }

    &__details {
      display: flex;
      justify-content: flex-start;

      --sf-input-padding-top: 0.5rem;
      --sf-input-padding-bottom: 0.5rem;
      --sf-input-padding-left: 1rem;
    }

    &__sku {
      font-size: 0.875rem;
      margin-top: 0.125rem;
      color: var(--sf-cart-item-sku-color);
    }

    &__stock {
      .stock__icon {
        display: none;
      }

      &__warning {
        font-size: 0.75rem;
        font-weight: var(--font-weight-semi);
        color: var(--sf-status-color-severe);
      }
    }

    &__amount {
      margin-top: 1rem;

      .control__content {
        width: min-content;
      }
    }

    &__buttons {
      display: flex;
      gap: 0.5rem;
      font-size: 1.125rem;
    }

    .list-card__image {
      flex: 0 0 120px;
    }

    @include media.query(lg) {

      &__title:hover {
        box-shadow: none
      }

      .list-card__actions {
        gap: 0.5rem
      }
    }
  }

  @include media.query(md) {
    .cart-item {
      --sf-list-card-gap: 0.75rem;
      --sf-product-price-current-font-size: 1rem;

      &__amount {
        margin-top: 0;
      }

      &__buttons {
        margin-top: 0.75rem;
      }

      &__title {
        font-size: 0.875rem;
      }

      &__sku {
        font-size: 0.75rem;
      }

      .list-card__wrapper {
        padding: 1rem 0.5rem;

        .list-card__image {
          flex: 0 0 70px;
        }

        .list-card__actions-content {
          gap: 0.75rem;
        }

        .list-card__content-default {
          gap: 0.75rem;
        }
      }

      .cart-item__buttons {
        margin-top: 0;
      }
    }
  }
</style>
