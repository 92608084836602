<script lang="ts" setup>
  import { computed, ref, type PropType } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { useShared } from '@plenny/visitor';
  import { SfModal } from '$components';
  import type { Brand } from '$types';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    price: { type: Object, required: true },
    attributes: { type: Object, required: true },
    brands: { type: Array as PropType<Brand[]>, required: true },
    search: { type: String, required: false },
    options: { type: Object, required: true },
    categories: { type: Object, required: true },
    products: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();
  const shared = useShared();

  const areFiltersOpen = ref(false);

  const filters = computed(() => !breakpoints.value.lg ? SfModal : 'div');
  const hasProducts = computed(() => props.products.data.length > 0);
  const type = computed(() => shared.value.product_list_mode);
</script>
<template>
  <div class="products">
    <component :is="filters" v-model:open="areFiltersOpen" class="products__filters-content">
      <SfModalHeader v-model:open="areFiltersOpen" v-if="!breakpoints.lg ">
        {{ $t('Filtry') }}
      </SfModalHeader>
      <ShopProductsFilter class="products__modal-filters" v-bind="{ categories, brands, price, attributes, options, search }" />
    </component>
    <div class="products__content">
      <ShopProductsGridControl @onFiltersOpen="areFiltersOpen = true" />
      <div v-if="hasProducts" :class="{ 'products__wrapper--list': type === 1 }" class="products__wrapper">
        <ProductCard v-for="product in products.data" :key="product.id" :horizontal="type === 1" :product="product" class="products__product-card" />
      </div>
      <div v-else class="products__empty">
        <SfDataPlaceholder>{{ $t('Przepraszamy, nie znaleźliśmy produktów spełniających podane kryteria.') }}</SfDataPlaceholder>
      </div>
      <SfPagination :pagination="products" />
    </div>
  </div>
</template>
<style lang="scss">
  @use '$assets/mixins/media';

  :root {
    --sf-products-columns: 4;
    --sf-products-columns-list: 1;

    @include media.query(xxl) {
      --sf-products-columns: 3;
    }

    @include media.query(xl) {
      --sf-products-columns: 2;
    }

    @include media.query(lg) {
      --sf-products-columns: 3;
    }

    @include media.query(md) {
      --sf-products-columns: 2;
    }

    @include media.query(sm) {
      --sf-products-columns: 1;
    }
  }
</style>
<style scoped lang="scss">
  .products {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-default);
    width: 100%;

    @media (max-width: 992px) {
      flex-direction: column;
    }
    @media(min-width: 992px) {

      &__filters-content {
        width: 23rem;
        background-color: var(--background-card);
        border-radius: var(--border-radius);
        padding: 1rem 1.5rem;
        flex: 0 0 23rem;
      }
    }

    &__modal-filters {
      flex: 1;
    }

    &__content {
      width: 100%;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(var(--sf-products-columns), minmax(180px, 1fr));
      gap: var(--gap-card);
      margin-top: var(--margin-default);
      padding: var(--padding-card);
      border-radius: var(--border-radius);
      background-color: var(--background-card);

      &--list {
        grid-template-columns: var(--sf-products-columns-list);
      }
    }

    &__empty {
      margin-top: var(--margin-default);
      padding: var(--padding-card);
      border-radius: var(--border-radius);
      background-color: var(--color-white);
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
    }
  }
</style>
