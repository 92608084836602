<script setup lang="ts">
  import { ref, computed } from 'vue';
  import { useCustomerOrderAbility, useCustomerAccepted } from '$storefront';
  import { route, useShared, useSession } from '@plenny/visitor';
  import { Features } from '$features';

  const variant = defineModel('variant', { type: Object, required: true });

  defineProps({
    product: { type: Object, required: true },
  });

  const shared = useShared();
  const session = useSession();
  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const successModalVisible = ref(false);

  const catalog = computed(() => {
    if (Features.streamsoftContractorPrices.isEnabled() && session.value.user && session.value.user.b2b) {
      return shared.value.include_vat ? variant.value.catalogGross : variant.value.catalogNet;
    }
  });

  const discount = computed(() => {
    if (Features.streamsoftContractorPrices.isEnabled() && session.value.user && session.value.user.b2b) {
      if (variant.value.catalogDiscount && variant.value.catalogDiscount > 0) {
        return variant.value.catalogDiscount;
      }
    }
  });

  const showSuccessModal = () => {
    successModalVisible.value = true;
  };
</script>
<template>
  <div class="picker">
    <SfVariantPicker series :product v-model:variant="variant" />
    <template v-if="canCustomerOrder">
      <div v-if="catalog && discount" class="picker__catalog">
        <span>{{ $t('Cena katalogowa') }}</span>
        <SfMoney :value="catalog" />
        <span>{{ $t('Twoj rabat') }}</span>
        <SfFormattedPercentage :value="discount" :options="{ maximumFractionDigits: 2 }" />
      </div>
      <div class="picker__price">
        <SfProductPrice :price="variant" presentation />
        <SfProductOmnibus :price="variant" />
        <SfProductMultipackUnitPrice :price="variant" />
        <SfProductUnitPrice :price="variant" />
      </div>
      <div class="picker__annotations">
        <SfProductStock :variant />
        <SfProductPoints :variant />
        <SfProductRealizationTime :variant :product/>
      </div>
      <SfCartStoreButton :variant @added="showSuccessModal" />
      <CartButtonSuccessModal :open="successModalVisible" :product :variant @update:open="successModalVisible = $event" />
    </template>
    <template v-else>
      <div class="picker__guest">
        <VisitorLink v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" rel="nofollow">
          {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
        </VisitorLink>
        <span v-else>
          {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
        </span>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
  .picker {
    display: flex;
    flex-direction: column;
    gap: var(--gap-card);

    &__annotations,
    &__price {
      display: flex;
      flex-direction: column;
    }

    &__annotations {
      gap: 0.5rem;
    }

    &__price {
      gap: 0.125rem;
    }

    &__guest {
      color: var(--secondary-color);
      font-weight: var(--font-weight-bold);
      font-size: 0.875rem;
    }
  }
</style>
